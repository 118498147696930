import {acceptHMRUpdate, defineStore} from "pinia";

export const useClinics = defineStore("clinics", {
    state: () => {
        const config = useRuntimeConfig();

        return {
            apiBase: config.public.apiBase,
            cache: config.public.cache,
            clinicId: "",
            listClinics: [],
            listRegions: {
                msk: "Москва",
                'msk-leninsky': "Москва",
                ufa: "Уфа",
                sochi: "Сочи",
                krasnodar: "Краснодар",
                spb: "Санкт-Петербург",
                perm: "Пермь",
            },
            listRegionsIn: {
                msk: "Москве",
                'msk-leninsky': "Москве",
                ufa: "Уфе",
                sochi: "Сочи",
                krasnodar: "Краснодаре",
                spb: "Санкт-Петербурге",
                perm: "Пермь",
            }
        };
    },

    getters: {
        clinicByRegion: (state) => (region) => {
            return state.listClinics.find(
                (item) => item.attributes.region === region
            );
        },
        clinic: (state) => {
            return state.listClinics.find(
                (item) => item.attributes.clinicId === state.clinicId
            );
        },
        clinicRegion: (state) => {
            let clinic = state.listClinics.find(
                (item) => item.attributes.clinicId === state.clinicId
            );

            return clinic.attributes.region;
        },
        clinicCoords: (state) => {
            return state.listClinics
                .find((item) => item.attributes.clinicId === state.clinicId)
                ?.attributes?.mapcenter.split(",")
                .map((item) => {
                    return parseFloat(item);
                });
        },
        getClinicById: (state) => (clinicId) => {
            return state.listClinics.find(
                (item) => item.attributes.clinicId === clinicId
            );
        },
        regionsWithClinics: (state) => {
            const result = {};

            for (let i = 0; i < state.listClinics.length; i++) {
                if (!result[state.listClinics[i].attributes["region"]]) {
                    result[state.listClinics[i].attributes["region"]] = [];
                }
                result[state.listClinics[i].attributes["region"]].push(
                    state.listClinics[i]
                );
            }

            return result;
        },
        getRegionIdByClinicId: (state) => (clinicId) => {
            let clinic = state.listClinics.find(
                (clinic) => clinic.attributes.clinicId == clinicId
            );
            if (clinic) {
                return clinic.attributes.region;
            }
        },
        getRegionByClinicId: (state) => (clinicId) => {
            let clinic = state.listClinics.find(
                (clinic) => clinic.attributes.clinicId == clinicId
            );
            if (clinic) {
                return state.listRegions[clinic.attributes.region];
            }
        },
        getRegionInByClinicId: (state) => (clinicId) => {
            let clinic = state.listClinics.find(
                (clinic) => clinic.attributes.clinicId == clinicId
            );
            if (clinic) {
                return `в ${state.listRegionsIn[clinic.attributes.region]}`;
            }
        },
        createApiUrl: (state) => (url) => {
            return `${state.apiBase}${url}`;
            // return `${state.apiBase}${url}${
            //     state.cache ? "" : "&" + new Date().getTime()
            // }`;
        },
    },

    actions: {
        async fetchClinics() {
            let listClinics = await fetch(
                this.createApiUrl(`/api/clinics?populate=deep,2`)
            )
                .then((response) => response.json())
                .then((result) => result.data)
                .catch((error) => console.log("error", error));
            if (listClinics?.length) {
                this.listClinics = [...listClinics];
            } else {
                console.log("List clinics is empty");
            }
        },
        setClinicId(clinicId) {
            this.clinicId = clinicId;
            window.localStorage.setItem("clinicId", clinicId);
        },
        unsetClinicId() {
            this.clinicId = "";
            window.localStorage.removeItem("clinicId");
        },
    },
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useClinics, import.meta.hot));
}
